import React from 'react';

const Ph = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="38.276"
    height="45.605"
    viewBox="0 0 38.276 45.605"
  >
    <defs>
      <clipPath>
        <rect width="38.276" height="45.605" transform="translate(0 0)" fill="currentColor" />
      </clipPath>
    </defs>
    <g transform="translate(-1769.862 -694.198)">
      <g transform="translate(1769.862 694.198)">
        <g clipPath="url(#clip-path)">
          <path
            d="M9.991,7.208c1.34-1.26,2.632-2.527,3.981-3.73,1.116-1,2.283-1.938,3.458-2.864A2.7,2.7,0,0,1,20.816.58a57.286,57.286,0,0,1,14.03,15.1A22.782,22.782,0,0,1,38,23.556a18.149,18.149,0,0,1-1.4,10.637.836.836,0,0,0,.081.919,8.6,8.6,0,0,1,1.486,3.252,5.888,5.888,0,0,1-1.332,4.861,6.189,6.189,0,0,1-9.1.713.475.475,0,0,0-.616-.093A19.057,19.057,0,0,1,1.549,33.914a18.035,18.035,0,0,1,.538-15.727.575.575,0,0,0-.144-.817A5.9,5.9,0,0,1,.671,10.362,26.683,26.683,0,0,1,4.613,4.9c.177-.2.359-.395.542-.589A1.391,1.391,0,0,1,7.5,4.3c.771.862,1.511,1.751,2.264,2.628.077.09.151.181.229.275m1.539,2.26c.13.254.22.439.318.62a5.726,5.726,0,0,1-.2,6.155,6.264,6.264,0,0,1-6.623,2.776c-.232-.048-.367-.038-.488.209a15.982,15.982,0,0,0-1.61,10A16.48,16.48,0,0,0,24.436,42.044c.539-.183,1.061-.413,1.534-.6-.117-.685-.262-1.278-.313-1.879a6.3,6.3,0,0,1,1.113-3.729,29.568,29.568,0,0,1,4.052-5.227,1.38,1.38,0,0,1,2.285.02c.471.5.929,1.019,1.405,1.541.043-.053.07-.073.079-.1a15.434,15.434,0,0,0,.784-8.046,21.143,21.143,0,0,0-3.117-7.458,56.21,56.21,0,0,0-12.826-13.7.405.405,0,0,0-.61.03c-1.075.91-2.189,1.776-3.231,2.721-1.382,1.253-2.71,2.565-4.061,3.852M31.959,33.32a21.981,21.981,0,0,0-3.179,4.413,3.742,3.742,0,0,0-.449,1.469,3.66,3.66,0,0,0,4.382,3.636c2.213-.486,3.428-3.025,2.633-4.685a22.713,22.713,0,0,0-3.387-4.833M6.329,6.963a18.135,18.135,0,0,0-3.46,5.128A2.919,2.919,0,0,0,2.8,13.649a3.415,3.415,0,0,0,2.679,2.727,3.436,3.436,0,0,0,3.717-1.3,3.06,3.06,0,0,0,.3-3.724C8.854,10.307,8.142,9.3,7.435,8.3c-.32-.455-.708-.861-1.106-1.338"
            fill="currentColor"
          />
          <path
            d="M12.649,29.588c0,1.008,0,1.986,0,2.964,0,.948-.519,1.558-1.325,1.565a1.365,1.365,0,0,1-1.346-1.543c0-3.188,0-6.377,0-9.565a1.4,1.4,0,0,1,.685-1.35,1.322,1.322,0,0,1,1.5.11.537.537,0,0,0,.428.007,4.185,4.185,0,1,1,.513,7.93l-.453-.118m1.5-2.416a1.511,1.511,0,1,0-1.494-1.549,1.519,1.519,0,0,0,1.494,1.549"
            fill="currentColor"
          />
          <path
            d="M22.615,21.747a4.148,4.148,0,0,1,4.053.6A3.993,3.993,0,0,1,28.3,25.562c.026.948.011,1.9.005,2.846A1.338,1.338,0,1,1,25.64,28.4c0-.875,0-1.749,0-2.624a1.515,1.515,0,1,0-3.021.006c0,.875,0,1.75,0,2.624a1.339,1.339,0,1,1-2.669,0q0-4.869,0-9.739a1.34,1.34,0,1,1,2.669.013c0,1.007,0,2.015,0,3.072"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Ph;
